$(function () {

    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });

    //$('.content-scroll').mCustomScrollbar();

    $('#article-nav-left .nav-link').bind('click', function (e) {
        e.preventDefault();

        $('#article-nav-left .nav-link').removeClass('active');
        $(this).addClass('active');

        var target = $(this).attr("href");

        $('html, body').stop().animate({
                scrollTop: $(target).offset().top + 50
        }, 600);

        return false;
    });


    $('.same-height').matchHeight();

    $('.picture-rotate').click(function(){
    	var img = $(this).attr('data-img');
    	var imgId = $(this).attr('data-imgId');
    	var version = $(this).attr('data-version');
    	var sens = $(this).attr('data-sens');
    	$.ajax({
    		type: 'GET',
    		processData: true,
    		url: '/admin/picture/rotate/'+img+'/'+version+'/'+sens,
    		dataType: 'html',
    		success: function(data){
        		$('#'+imgId).attr('src',data);
    		}
    	});
	});

    function nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
    //$('#search-hideseek').hideseek();

    $('.selectpicker').selectpicker({
        showSubtext : true
    });

    $('[rel="tooltip"]').tooltip();

    //$('.ui-sortable, .liste_triable').sortable();

    $(".radio-switch").bootstrapSwitch({
        size : 'small',
    });

    var datatable = $('.datatable').DataTable( {
        "info":     false,
        "ordering": false,
        "paging": false,
        "columnDefs": [{
            "targets": 'sorting',
            "orderable": false,
        }],
        language: {
            processing:     "Traitement en cours...",
            search:         "Rechercher :",
            lengthMenu:     "Afficher _MENU_ éléments",
            info:           "Affichage de l'élément _START_ au _END_ sur _TOTAL_ éléments",
            infoEmpty:      "Affichage de l'élément 0 Ã  0 sur 0 éléments",
            infoFiltered:   "(filtré de _MAX_ éléments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun élément à afficher",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Précédent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            }
        },
        "aoColumnDefs": [
          { "bSearchable": true, "aTargets": [ 0,1 ] },
          { "orderable": false, "targets": -1 }
        ],
        "initComplete": function () {
            $('#table-articles_wrapper > div:first-child > div:first-child ').append($('#btn-add-item'));
        }
    });

    if ($('.liste_triable').length) {
        $(".liste_triable").each(function() {
            liste=$(this);
            //alert("[data-connection='"+$(this).attr('data-connection')+"']");
            $(this).sortable({
                dropOnEmpty: false,
                axis:liste.attr("data-axis"),
                items: ".triable",
                cancel: ".not-triable",
                handle: ".sort-handler",
                helper : 'clone',
                connectWith: "[data-connection='"+$(this).attr('data-connection')+"']",
                update : function ()
                {
                    var order = $(this).sortable("serialize");
                    $.post(
                        $(this).attr("data-process"),
                        'prefixe='+$(this).attr("data-item")+'&dest_box='+$(this).attr('data-dest')+'&'+order
                    );

                }
            });
        });
    }

    $('.form-auto .radio-switch').on('switchChange.bootstrapSwitch', function (event, state) {
        formulaire=$(this).parents('form');
        submit_form(formulaire.attr('action'),formulaire.attr('id'));
    });

    $('.selectpicker').on('change',function(){
        formulaire=$(this).parents('form');
        submit_form(formulaire.attr('action'),formulaire.attr('id'));
        //$('.saveBtn').removeClass('btn-secondary');
        //$('.saveBtn').addClass('btn-success');
        //$('.saveBtn').removeAttr('disabled');
    });

    /*
    let x = moment();
    x.locale(['fr']);
    console.log(x.format('LLLL'));
    console.log('language: '+window.navigator.language);
    //moment.format('DD/MM/YYYY HH:mm');
    */

    $('#datetimepicker1').datetimepicker({
        locale: 'fr'
    });

    $("#datetimepicker1").on("change.datetimepicker", function (e) {
        $('#main-fixe-date').val(moment(e.date._d).format('DD/MM/YYYY HH:mm'));
        //console.log(e.date._d);
        //formulaire=$(this).parents('form');
        //submit_form(formulaire.attr('action'),formulaire.attr('id'));

        $('.saveBtn').removeClass('btn-secondary');
        $('.saveBtn').addClass('btn-success');
        $('.saveBtn').removeAttr('disabled');
    });

    $("#planification-start").datetimepicker({
        locale: 'fr'
    });
    $("#planification-end").datetimepicker({
        locale: 'fr',
        useCurrent: false
    });

    $("#planification-start").on("change.datetimepicker", function (e) {
        $('#main-planification-start').val(moment(e.date._d).format('DD/MM/YYYY HH:mm'));
        //console.log(e.date._d);
        formulaire=$(this).parents('form');
        submit_form(formulaire.attr('action'),formulaire.attr('id'));

        //$('.saveBtn').removeClass('btn-secondary');
        //$('.saveBtn').addClass('btn-success');
        //$('.saveBtn').removeAttr('disabled');
    });

    $("#planification-end").on("change.datetimepicker", function (e) {
        $('#main-planification-end').val(moment(e.date._d).format('DD/MM/YYYY HH:mm'));
        //console.log(e.date._d);
        formulaire=$(this).parents('form');
        submit_form(formulaire.attr('action'),formulaire.attr('id'));

        //$('.saveBtn').removeClass('btn-secondary');
        //$('.saveBtn').addClass('btn-success');
        //$('.saveBtn').removeAttr('disabled');
    });

    $('.form-auto').on('change paste keydown keypress',function(){
        formulaire=$(this);
        submit_form(formulaire.attr('action'),formulaire.attr('id'));
    });

    $('.linked').on('change paste keydown keypress',function(){
        $('.'+$(this).attr('data-title-link')).html(nl2br($(this).val()));
    });


    $('[data-link]').on('click', function(e) {
        e.preventDefault();
        if ($(this).data('target')=="new") {
            var win = window.open($(this).data('link'), '_blank');
              win.focus();
        }
        else { location.href = $(this).data('link'); }

    });

    function submit_form(page_process,nom_form) {
        $.post(page_process,$('#'+nom_form).serialize());
    }

    $('.show-modal').click(function() {
        var route = $(this).attr('data-route');
        var params = $(this).attr('data-params');
        $.ajax({
            type: 'POST',
            processData: true,
            url: '/show-modal/'+route,
            dataType: 'html',
            data:params,
            success: function(data){
                $('#general_modal .modal-content').html(data);
                $('#general_modal').modal('show');
            }
        });
    });

    $('.delete-image').click(function() {
        var imageId = $(this).attr('data-id');
        swal({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer cette image !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/admin/image/delete/','image_id='+imageId, function () {$('#block_'+imageId).hide('slow');});
            }
        });
    });

    $('.delete-member').click(function() {
        var memberId = $(this).attr('data-member');
        swal({
            title: "Etes-vous sur ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer cette fiche !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/admin/member/delete/'+memberId, function () {document.location.href="/";});

            }
        });
    });

    function update(calque,page,params) {
        $.ajax({
            type: 'POST',
            processData: true,
            url: page,
            dataType: 'html',
            data:params,
            success: function(data){
            //alert(data);
            if (calque>'') {$('#'+calque).html(data);}
            }
        });
    }

    $('.changeTab').click(function(){
        $.get('/change-tab/'+$(this).attr('data-name')+'/'+$(this).attr('data-value'));
    });

    $('.delete-link').click(function() {
        var linkId = $(this).attr('data-id');
        swal({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer ce lien !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/admin/link/delete/'+linkId, function () {$('#link_'+linkId).fadeOut();});
            }
        });
    });

    $('.delete-article').click(function() {
        var articleId = $(this).attr('data-article');
        var refresh = ($(this).attr('data-refresh') == '1');
        swal({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer cet article !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/admin/articles/delete/'+articleId, function () {
                    if (refresh) {
                        window.location.href="/admin";
                    } else { $('#article'+articleId).fadeOut();}
                });
            }
        });
    });

    var instances=new Array(); var value=new Array(); var i=0;

    CKEDITOR.disableAutoInline = true;
    CKEDITOR.disableNativeSpellChecker = true;
    CKEDITOR.on('instanceCreated', function (e) {
        instances[i]=e.editor; value[e.editor.id]=e.editor.getData(); i++;
        e.editor.on('change', function (ev) {
            ev.editor.updateElement();
            nom_zone=ev.editor.name;
            formulaire=$("#"+nom_zone).parents('form');
            if (formulaire.hasClass('form-auto')) {
                e.editor.on('change', function (ev) {
                    ev.editor.updateElement();
                    submit_form(formulaire.attr('action'),formulaire.attr('id'));
                });
            }
            else if ($("#"+nom_zone).parents('.global-form').length) {
                $('.saveBtn').removeClass('btn-secondary');
                $('.saveBtn').addClass('btn-success');
                $('.saveBtn').removeAttr('disabled');
            }
        });
    });
    
    var options = {
        customConfig : '../ckeditor/vide.js',
        language: 'fr'
        //filebrowserImageBrowseUrl: '/laravel-filemanager?type=Images',
        //filebrowserBrowseUrl: '/laravel-filemanager?type=Files'
    };

    $("textarea.withEditor").each(function(champ) {
        zone_id=$(this).attr('id');
        formulaire=$(this).parents('form');
        CKEDITOR.replace(zone_id,options);
    });

    $('.saveBtn').click(function(){
        $(this).parents('.global-form').each(function() {
            formulaire=$(this);
            submit_form(formulaire.attr('action'),formulaire.attr('id'));
            instances.forEach(function(instance) {
               value[instance.id] = instance.getData();
            });
        });
        swal('Enregistrement','Vos modifications ont bien été enregistrées','success');
        $(this).removeClass('btn-success');
        $(this).addClass('btn-secondary');
        $(this).attr('disabled',true);
    });

    $('.global-form, .global-form input, .global-form textarea').on('change paste keydown',function(e){
        $('.saveBtn').removeClass('btn-secondary');
        $('.saveBtn').addClass('btn-success');
        $('.saveBtn').removeAttr('disabled');
    });
    /*
    $('.global-form').mouseleave(function(e){
	    var btn = $(this).find('.saveBtn');
        if (btn.hasClass('btn-success')) {
            if ((!$('.cke_dialog_background_cover:visible')[0]) && (!$('.cke_panel:visible')[0])){
                swal({
                    title: "Données modifées",
                    text: "Vous avez effectué des modifications. que souhaitez-vous faire ?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#4dbd74",
                    confirmButtonText: "Enregistrer",
                    cancelButtonText: "Annuler mes changements",
                    closeOnConfirm: false,
                    closeOnCancel: true
                }, function(isConfirm)
                {
                    if (isConfirm) {
                       btn.click();
                    } else {
                        $('.global-form').trigger("reset");
                        $('.saveBtn').removeClass('btn-success');
                        $('.saveBtn').addClass('btn-secondary');
				        $('.saveBtn').attr('disabled',true);
                        instances.forEach(function(instance) {
                            nom_zone=instance.name;
                            formulaire=$("#"+nom_zone).parents('form');
                            if (!formulaire.hasClass('form-auto')) {
                                instance.setData( value[instance.id], function() {
                                    this.updateElement();
                                    $('.saveBtn').removeClass('btn-success');
                                    $('.saveBtn').addClass('btn-secondary');
							        $('.saveBtn').attr('disabled',true);
                                } );
                            }
                        });
                    }
                });
            } else { console.log($('.cke_panel:visible').attr('id')); }
        }
    });*/
});