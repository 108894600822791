require('jquery-ui-bundle');
require('../bootstrap');
require('@coreui/coreui-pro');
require('datatables.net-bs4');

//require('popper.js');
//require('hideseek');
require('../../libs/jquery-sweetalert/sweetalert-dev.js');
require('../../libs/jquery-mask/jquery.mask.js');
require('bootstrap-select');
require('../../libs/jquery.matchHeight.js');
require('dropzone');
require('../../libs/jquery-cropit/jquery.cropit.js');
require('../../libs/bootstrap-switch/bootstrap-switch.min.js');
//require('bootstrap-datepicker');

window.moment = require('moment');
//window.momentTz = require('moment-timezone');
require('tempusdominus-bootstrap-4');
require('malihu-custom-scrollbar-plugin');

//require('../../libs/jscroll/dist/jquery.jscroll.min.js');